import { Grid, Button } from "@mui/material";
import React from "react";
import { useContext, useState } from "react";
import { LabelContext } from "../../hooks/InputFeild";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
export default function PlasteringStep({ plans }) {
  let labelContext = useContext(LabelContext);
  const { t } = useTranslation();
  let [hidden, setHidden] = useState(
    labelContext.inputs.plasteringChoice ? "" : "none"
  );

  function hide(e) {
    if (!e.target.classList.contains("active")) {
      setHidden("none");
      labelContext.handlePlastering(false, plans[0]);
    }
  }

  function show(e) {
    if (!e.target.classList.contains("active")) {
      setHidden("");
      labelContext.handlePlastering(true, plans[0]);
    }
  }
  return (
    <React.Fragment>
      {hidden && (
        <React.Fragment>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            className="plastering-button"
          >
            <Grid item xs={9} md={4} sx={{ paddingTop: "0px" }}>
              <Button
                onClick={show}
                size="large"
                variant={"outlined"}
                sx={{ display: "block", mt: "30px", mx: "auto", width: "100%" }}
              >
                {t("Yes")}
              </Button>
            </Grid>
            <Grid item xs={9} md={4}>
              <Button
                className="active"
                onClick={hide}
                size="large"
                variant="contained"
                sx={{ display: "block", mt: "30px", mx: "auto", width: "100%" }}
              >
                {t("No")}
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      {!hidden && (
        <React.Fragment>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            className="plastering-button"
          >
            <Grid item xs={9} md={4} sx={{ paddingTop: "0px" }}>
              <Button
                className="active"
                onClick={show}
                size="large"
                variant={"contained"}
                sx={{
                  display: "block",
                  mt: "30px",
                  mx: "auto",
                  width: "100%",
                }}
              >
                {t("Yes")}
              </Button>
            </Grid>
            <Grid item xs={9} md={4}>
              <Button
                onClick={hide}
                size="large"
                variant="outlined"
                sx={{
                  display: "block",
                  mt: "30px",
                  mx: "auto",
                  width: "100%",
                }}
              >
                {t("No")}
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      <Grid>
        <Typography
          className="mr note"
          sx={{ textAlign: "center", width: "100%" }}
          gutterBottom
          variant="p"
          component="p"
        >
          {t("Note: Plastering is the process of applying a thin layer of cement to the walls and ceiling of a house to create a smooth and even surface.")}
        </Typography>
      </Grid>
    </React.Fragment>
  );
}
