import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
export default function SocialMedia() {
  return (
    <div
    className="socialmedia"
      style={{
        position: "fixed",
        top: "50%",
        right: "30px",
        transform: "translateY(-50%)",

      }}
    >
      <a style={{color: "white", display: "block", marginBottom: "20px"}} href="https://www.facebook.com/share/1ALp1rxqVU/?mibextid=qi2Omg" target="_blank">
        <FacebookIcon id='facebook' />
      </a>
      <a style={{color: "white", display: "block", marginBottom: "20px"}} href="https://www.instagram.com/10sapglobal" target="_blank">
        <InstagramIcon id='instagram' />
      </a>
      <a style={{color: "white", display: "block"}} href="whatsapp://send?abid=+201272999939" target="_blank">
        <WhatsAppIcon id='whatsapp' />
      </a>
    </div>
  );
}
