import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import Steps from "./components/steps";
import CssBaseline from "@mui/material/CssBaseline";
import { Route } from "react-router-dom";
import { LabelContext, UseForm } from "./components/hooks/InputFeild";
import PlansSteps from "./components/steps/plans-steps/PlansSteps";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import ProtectedRoute from "./components/ProtectedRoute";
import FinalInvoice from "./components/steps/plans-steps/FinalInvoice";
import { Redirect } from "react-router-dom";
import Profile from "./components/profile/Profile";
import ResponsiveAppBar from "./components/toolbar/toolbar";
import { useDispatch, useSelector } from "react-redux";
import Units from "./components/profile/units/Units";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import ResetPassword from "./components/forgot-password/ResetPassword";
import i18next from "i18next";
import MakeVisit from "./components/make-visit/MakeVisit";
import { authActions } from "././components/hooks/auth";
import axios from "axios";
import SocialMedia from "./components/SocialMedia";

import("./style.css");

function App() {
  const [lang, setLang] = useState("en");
  let isLogged = useSelector((state) => state.auth.isLogged);
  i18next.on("languageChanged", function (lng) {
    setLang(lng);
  });

  let dispatch = useDispatch();

  function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  useEffect(() => {
    if(!isJson(localStorage.getItem('userData'))){
      dispatch(authActions.logoutHandler());
    }
  },[])

  axios.interceptors.response.use(config => {

    return config
  }, err => {
    if(err?.response?.status === 401){
      dispatch(authActions.logoutHandler());
      throw err
    }
  })


  return (
    <React.Fragment key={lang}>
      <div className={lang === "ar" && "rtl"}>
        <ResponsiveAppBar isLogged={isLogged} />

        <SocialMedia />
        <CssBaseline />

        <Route path="/" exact={true}>
          <Redirect to="/login" />
        </Route>

        <ProtectedRoute path="/:lang(ar)?/login" type="notAuthed">
          <Login />
        </ProtectedRoute>

        <ProtectedRoute path="/:lang(ar)?/register" type="notAuthed">
          <Register />
        </ProtectedRoute>

        <ProtectedRoute path="/:lang(ar)?/forgotpassword" type="notAuthed">
          <ForgotPassword />
        </ProtectedRoute>

        <ProtectedRoute path="/:lang(ar)?/reset-password" type="notAuthed">
          <ResetPassword />
        </ProtectedRoute>

        <ProtectedRoute path="/:lang(ar)?/create-unit" type="authed">
          <Steps />
        </ProtectedRoute>

        <ProtectedRoute path="/:lang(ar)?/unit-plans" type="authed">
          <PlansSteps />
        </ProtectedRoute>

        <ProtectedRoute path="/:lang(ar)?/invoice/:id" type="authed">
          <FinalInvoice />
        </ProtectedRoute>

        <ProtectedRoute path="/:lang(ar)?/user-units" type="authed">
          <Units />
        </ProtectedRoute>

        <ProtectedRoute path="/:lang(ar)?/profile" type="authed">
          <Profile />
        </ProtectedRoute>


        <ProtectedRoute path="/:lang(ar)?/make-visit/:id" type="authed">
          <MakeVisit />
        </ProtectedRoute>
      </div>
    </React.Fragment>
  );
}

export default App;
